<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Locations</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/locations/create" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="Add New Type"
                  @click="navigate"
                >
                  <i class="fas fa-plus-circle pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <b-form-input
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Filter by location name"
                    v-model="search"
                  ></b-form-input>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="locations"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.parent`]="{ item }">
                  <span
                    v-if="item.parent"
                    class="label label-inline label-light-primary"
                    >{{ item.parent }}</span
                  >
                  <span
                    v-if="!item.parent"
                    class="label label-inline label-light-info"
                    >PRIMARY LOCATION</span
                  >
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="w-100 text-right">
                    <span
                      @click="viewDetail(item)"
                      v-b-tooltip.hover.top="'Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="editItem(item.id)"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="deleteItem(item.id)"
                      v-b-tooltip.hover.top="'Delete'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
              <b-modal
                ref="item-detail"
                hide-footer
                title="Observation Type Detail"
              >
                <div class="d-block">
                  <div class="row">
                    <div class="col-md-5">Location Name:</div>
                    <div class="col-md-7 mb-3">
                      <b>{{ name }}</b>
                    </div>
                    <div class="col-md-5">Parent Location:</div>
                    <div class="col-md-7 mb-3">
                      <b>{{ parent }}</b>
                    </div>
                    <div class="col-md-12 d-flex justify-content-end">
                      <b-button
                        class="btn btn-light-danger font-weight-bold mt-3"
                        @click="hideDetail"
                        >Close</b-button
                      >
                    </div>
                  </div>
                </div>
              </b-modal>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LocationService from "@/core/services/location.service.js";
import PilotCard from "@/view/content/Card.vue";
// import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Location",
          align: "start",
          value: "name"
        },
        { text: "Parent", value: "parent" },
        { text: "Actions", align: "end", sortable: false, value: "action" }
      ],
      parent: "",
      locations: [],
      name: ""
    };
  },
  components: {
    PilotCard
  },
  methods: {
    getColor(status) {
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this location",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LocationService.delete(id)
            .then(response => {
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Location Deleted",
            "Location was successfully deleted"
          );
          this.getLocations();
        }
      });
    },
    viewDetail(location) {
      this.name = location.name;
      this.parent = location.parent;
      this.$refs["item-detail"].show();
    },
    hideDetail() {
      this.name = "";
      this.parent = "";
      this.$refs["item-detail"].hide();
    },
    editItem(id) {
      this.$router.push(`/locations/edit/${id}`);
    },
    getLocations() {
      this.locations = [];
      LocationService.filter().then(response => {
        const locationData = response.data;
        if (locationData.length > 0) {
          locationData.forEach(location => {
            let parentName = "";
            if (location.location_id !== null) {
              const parentLocation = locationData.find(
                loc => loc.id === location.location_id
              );
              if (parentLocation !== undefined) {
                parentName = parentLocation.name;
              }
            }

            const newLocation = {
              id: location.id,
              name: location.name,
              parent: parentName
            };

            this.locations.push(newLocation);
          });
        }
      });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Locations" }]);
    this.getLocations();
  }
};
</script>
